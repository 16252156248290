import Page from '../js/page.js';
import { getPath, registerPage, navigate } from '@trullock/page-manager';
import { listActivities } from '../js/activity-repository.js'
import { renderCard } from '../js/activities.js';

export default registerPage('root', '/', class extends Page {
	static requireAuth = true;

	constructor($page) {
		super($page);

		this.$grid = this.$page.$('.js-grid');
		this.$gridTemplate = this.$grid.$('.js-template');
		this.$gridTemplate.remove();
		this.$gridNoResults = this.$grid.$('.js-no-results');
		this.$btnAdd = this.$page.$('.js-add');

		this.$page.$('.js-clear-search').addClickListener(e => {
			e.preventDefault();
			this.$txtSearch.value = '';
			this.search('');
		});

		this.$txtSearch = this.$page.$('.js-search');
		this.$txtSearch.addEventListener('input', e=> {
			var val = (e.target.value || '').toLowerCase().trim();
			this.search(val);
		});

		this.$gridCards = [];
	}

	async boot(opts) {
		const unsub = await listActivities(snapshot => {
			let activities = snapshot.docs.map(doc => doc.data());
			this.hydrate(activities);
		});
	}

	hydrate(activities) {

		this.$gridCards.forEach($card => $card.remove());
		this.$gridCards = [];
		
		activities.forEach(activity => {
			var $gridCard = this.$gridTemplate.cloneNode(true);
			renderCard($gridCard, activity);
			this.$gridCards.push($gridCard);
			this.$grid.appendChild($gridCard);
		})
	}

	search(val)
	{
		var results = 0;
		this.$gridCards.forEach($card => {
			if (val == '' || $card.search.indexOf(val) > -1) {
				$card.classList.remove('d-none');
				results++;
			}
			else
				$card.classList.add('d-none');
		});
		if (results == 0 && val)
		{
			this.$gridNoResults.classList.remove('d-none');
		}
		else
		{
			this.$gridNoResults.classList.add('d-none');
		}
	}
})