import Page from '../js/page.js';
import { getPath, registerPage, navigate } from '@trullock/page-manager';
import { listActivitiesByTag } from '../js/activity-repository.js'

export default registerPage('tag-view', '/tags/{tag}', class extends Page {
	static requireAuth = true;

	constructor($page) {
		super($page);

		this.$grid = this.$page.$('.js-grid');
		this.$gridTemplate = this.$grid.$('.js-template');
		this.$gridTemplate.remove();
		this.$gridNoResults = this.$grid.$('.js-no-results');
		this.$btnAdd = this.$page.$('.js-add');

		this.$page.$('.js-clear-search').addClickListener(e => {
			e.preventDefault();
			this.$txtSearch.value = '';
			this.search('');
		});

		this.$txtSearch = this.$page.$('.js-search');
		this.$txtSearch.addEventListener('input', e=> {
			var val = (e.target.value || '').toLowerCase().trim();
			this.search(val);
		});

		this.$gridCards = [];
	}

	async boot(opts) {
		const activities = await listActivitiesByTag(opts.tag);
		this.hydrate(activities);
	}

	hydrate(activities) {

		this.$gridCards.forEach($card => $card.remove());
		this.$gridCards = [];
		
		activities.forEach(activity => {
			var $gridCard = this.$gridTemplate.cloneNode(true);
			this.renderCard($gridCard, activity);
			this.$gridCards.push($gridCard);
			this.$grid.appendChild($gridCard);
		})
	}

	search(val)
	{
		var results = 0;
		this.$gridCards.forEach($card => {
			if (val == '' || $card.search.indexOf(val) > -1) {
				$card.classList.remove('d-none');
				results++;
			}
			else
				$card.classList.add('d-none');
		});
		if (results == 0 && val)
		{
			this.$gridNoResults.classList.remove('d-none');
		}
		else
		{
			this.$gridNoResults.classList.add('d-none');
		}
	}

	renderCard($card, activity){
		$card.search = activity.title.toLowerCase();

		var $img = $card.$('.js-image');
		var $loader = $card.$('.spinner-4x3');
		$img.addEventListener('load', e=> {
			$loader.classList.add('d-none');
			$img.classList.remove('d-none')
		})
		$img.src = activity.mainImage;
		$img.alt = activity.title;

		$card.$('.js-edit').addClickListener(_ => {
			navigate(getPath('activity-edit', { activityId: activity.id }))
		});

		$card.$('.js-delete').addClickListener(_ => {
			showConfirm({
				title: 'Confirm',
				message: 'Are you sure you want to delete this activity?',
				positiveText: 'Delete',
				positiveStyle: 'danger',
				//positiveAction: () => projectRepository.deleteProject(proj.id)
			});
		});

		$card.$('.js-title').textContent = activity.title;

		$card.$('.js-view').forEach($a => {
			$a.href = getPath('activity-view', { activityId: activity.id });
		});

		$card.$('.js-title').textContent = activity.title;

		let $tagTemplate = $card.$('.js-tag-template');
		let $tagWrapper = $tagTemplate.parentElement;
		$tagTemplate.remove();

		for(var tag of activity.tags)
		{
			let $tag = $tagTemplate.cloneNode(true);
			$tag.textContent = tag;
			$tagWrapper.appendChild($tag);
			$tag.href = getPath('tag-view', { tag: tag});
		}
	}
})