import Page from '../js/page.js';
import * as pageManager from '@trullock/page-manager';
import { marked } from 'marked';
import * as activityRepository from '../js/activity-repository.js';
import { renderDanger, renderDifficulty, renderDuration, endsWithUuid } from '../js/utils.js';
import { renderTags } from '../js/activities.js';

export default pageManager.registerPage('activity-view', '/activities/{activityId}/view', class extends Page {
	static requireAuth = true;

	constructor($page) {
		super($page);

		this.$title = this.$page.$('.js-title');
		this.$mainImage = this.$page.$('.js-img');
		this.$body = this.$page.$('.js-body');
		this.$difficulty = this.$page.$('.js-difficulty');
		this.$danger = this.$page.$('.js-danger');
		this.$duration = this.$page.$('.js-duration');
		this.$sourceWrapper = this.$page.$('.js-source');
		this.$source = this.$sourceWrapper.$('span');

		this.$tagTemplate = this.$page.$('.js-tag-template');
		this.$tagWrapper = this.$tagTemplate.parentElement;
		this.$tagTemplate.remove();

		this.$index = this.$page.$('.js-index');
		this.$resources = this.$page.$('.js-resources');
	}

	async boot(opts){
		let unsub = await activityRepository.getActivity(opts.activityId, snapshot => {
			this.activity = snapshot.data();
		})
		this.hydrate();
	}

	renderIndex()
	{
		return marked.parse(this.activity.body || '', { 
			renderer: {
				code: () => '',
				blockquote: () => '',
				html: () => '',
				heading: (text, level, raw, slugger) => {
					return `<li class=""><a href="#${slugger.slug(raw)}" class="index-${level}">${text}</a></li>`
				},
				hr: () => '',
				list: () => '',
				listitem: () => '',
				checkbox: () => '',
				paragraph: () => '',
				table: () => '',
				tablerow: () => '',
				tablecell: () => '',

				strong: () => '',
				em: () => '',
				codespan: () => '',
				br: () => '',
				del: () => '',
				link: () => '',
				image: () => '',
				text: text => text,
			}
		})
	}

	renderFiles()
	{
		let links = [];
		marked.parse(this.activity.body || '', { 
			renderer: {
				code: () => '',
				blockquote: () => '',
				html: () => '',
				heading: () => '',
				hr: () => '',
				list: () => '',
				listitem: () => '',
				checkbox: () => '',
				paragraph: () => '',
				table: () => '',
				tablerow: () => '',
				tablecell: () => '',

				strong: () => '',
				em: () => '',
				codespan: () => '',
				br: () => '',
				del: () => '',
				link: (href, title, text) => {
					try
					{
						let url = new URL(href)
						if(endsWithUuid(url.pathname))
							links.push({ href, title, text });
					} catch(e) {}
					return '';
				},
				image: () => '',
				text: text => text,
			}
		})

		links.forEach(link => {
			let out = '<a href="' + link.href + '"';
			if (link.title)
				out += ' title="' + link.title + '"';
			
			out += ' target="_blank"><span class="fe fe-file"></span> ' + link.text + '</a>';
			this.$resources.insertAdjacentHTML('beforeend', out);
		})
	}

	hydrate()
	{
		this.$title.textContent = this.activity.title;

		this.$difficulty.value = this.activity.difficulty || 1;
		this.$danger.value = this.activity.danger || 1;
		this.$duration.value = this.activity.duration || 20;

		this.$mainImage.src = this.activity.mainImage;

		var html = marked.parse(this.activity.body || '', {
			headerIds: true
		});
		this.$body.innerHTML = html;

		renderDifficulty(this.$difficulty, this.activity.difficulty);
		renderDanger(this.$danger, this.activity.danger);
		renderDuration(this.$duration, this.activity.duration);
		renderTags(this.$tagTemplate, this.$tagWrapper, this.activity.tags)
		

		if(this.activity.source)
			this.$source.textContent = this.activity.source;
		else
			this.$sourceWrapper.classList.add('d-none');

		html = this.renderIndex();
		this.$index.innerHTML = html;
		
		this.renderFiles();
	}
});
