import Page from '../js/page.js';
import * as pageManager from '@trullock/page-manager';
import { marked } from 'marked';
import TurndownService  from '../js/turndown/turndown.js'
import turndownRules from '../js/turndown/commonmark-rules.js'
import {v4 as uuid} from 'uuid'
import * as activityRepository from '../js/activity-repository.js';
import { repeat } from '../js/turndown/utilities.js'
import { toTitleCase, cleanAttribute } from '../js/content-parsing.js';
import TomSelect from 'tom-select';
import { renderDanger, renderDifficulty, renderDuration, parseUuidReplacement } from '../js/utils.js';
import { getFileUrl, downloadYoutubeUrl } from '../js/firebase.js';

export default pageManager.registerPage('activity-edit', '/activities/{activityId}/edit', class extends Page {
	static requireAuth = true;

	constructor($page) {
		super($page);

		this.$form = $page.$('form');
		this.$title = this.$form.$('[name="title"]');
		this.$difficulty = this.$form.$('[name="difficulty"]');
		this.$danger = this.$form.$('[name="danger"]');
		this.$duration = this.$form.$('[name="duration"]');
		this.$mainImage = this.$form.$('[name="main-image"]');
		this.$markdown = this.$form.$('[name="markdown"]');
		this.$tags = this.$form.$('[name="tags"]');
		this.$source = this.$form.$('[name="source"]');

		this.$previewTitle = this.$page.$('.js-preview-title');
		this.$previewImage = this.$page.$('.js-preview-img');
		this.$previewBody = this.$page.$('.js-preview-body');
		this.$previewDifficulty = this.$page.$('.js-preview-difficulty');
		this.$previewDanger = this.$page.$('.js-preview-danger');
		this.$previewDuration = this.$page.$('.js-preview-duration');

		this.newFiles = {};
		this.newVideos = {};

		
		this.$mainImage.addPasteListener(data => this.handleMainImagePaste(data));

		this.$markdown.addPasteListener(data => this.handleBodyPaste(data));
		this.$markdown.addEventListener('input', e => this.updateBodyPreview());

		this.$title.addPasteListener(data => this.handleTitlePaste(data));
		this.$title.addEventListener('input', e => {
			this.$previewTitle.textContent = this.$title.value || 'Activity title';
		})


		this.$difficulty.addEventListener('input', e => this.updateMetaPreview());
		this.$danger.addEventListener('input', e => this.updateMetaPreview());
		this.$duration.addEventListener('input', e => this.updateMetaPreview());

		this.$form.submitHandler(() => {
			this.handleSubmit();
		});

		marked.use({ extensions: [
			{
				name: 'video',
				level: 'block',
				start(src) { 
					return src.match(/\$\[/)?.index; 
				}, // Hint to Marked.js to stop and check for a match
				tokenizer(src, tokens) {
					const rule = /^\$\[([^\]]+)\]\(([^\)]+)\)/;    // Regex for the complete token, anchor to string start
					const match = rule.exec(src);
					if (match) {
						const token = {                                 // Token to generate
							type: 'video',                      // Should match "name" above
							raw: match[0],                                // Text to consume from the source
							poster: match[1],
							video: match[2],
							tokens: []                                    // Array where child inline tokens will be generated
						};
						return token;
					}
				},
				renderer(token) {
					return `<video poster="${token.poster}" controls src="${token.video}" class="mb-3"></video>`; // parseInline to turn child tokens into HTML
				}
			}
		]})
	}
	
	async handleSubmit()
	{
		// handle new body images
		let bodyImages = this.$previewBody.$('img').map(async $img => {
			let imgId = $img.dataset['imgId'];
			if(!imgId)
				return;

			let imgName = imgId.substr(1, imgId.length - 2);
			let blob = await this.newFiles[imgName];
			let url = await activityRepository.uploadFile(imgName, blob);

			this.$markdown.value = this.$markdown.value.replaceAll(imgId, url);
		});

		// handle new body files
		let bodyFiles = this.$previewBody.$('a').map(async $a => {
			let id = $a.dataset['file'];
			if(!id)
				return;

			let name = id.substr(1, id.length - 2);
			let blob = await this.newFiles[name];
			let url = await activityRepository.uploadFile(name, blob);
			debugger;
			this.$markdown.value = this.$markdown.value.replaceAll(id, url);
		});

		// handle new videos
		for(const id in this.newVideos)
		{
			let result = await this.newVideos[id];
			this.$markdown.value = this.$markdown.value.replaceAll('[$' + id + '$]', '[' + result.posterUrl + ']');
			this.$markdown.value = this.$markdown.value.replaceAll('($' + id + '$)', '(' + result.videoUrl + ')');
		}

		// handle main image
		if(this.$mainImage.value.startsWith('$'))
		{
			let imgName = this.$mainImage.value.substr(1, this.$mainImage.value.length - 2);
			let blob = await this.newFiles[imgName];
			let url = await activityRepository.uploadFile(imgName, blob);
			this.$mainImage.value = url;
		}

		await Promise.all(bodyImages);
		await Promise.all(bodyFiles);

		await activityRepository.saveActivity({
			id: this.activity.id,
			title: this.$title.value,
			tags: this.tags.items,
			difficulty: parseInt(this.$difficulty.value, 10),
			danger: parseInt(this.$danger.value, 10),
			duration: parseInt(this.$duration.value, 10),
			mainImage: this.$mainImage.value,
			source: this.$source.value,
			body: this.$markdown.value
		})

		this.$form.setSubmitting(false);
		pageManager.navigate(pageManager.getPath('root'));

	}

	async handleTitlePaste(data)
	{
		if(data.textPlain)
		{
			this.$title.value = this.$title.value.substring(0, this.$title.selectionStart) + toTitleCase(data.textPlain) + this.$title.value.substr(this.$title.selectionEnd)
			this.$previewTitle.textContent = this.$title.value;
		}
	}

	async handleMainImagePaste(data)
	{
		if(data.image)
		{
			let id = uuid();
			this.newFiles[id] = Promise.resolve(data.image);
			this.$mainImage.value = this.$mainImage.value.substring(0, this.$mainImage.selectionStart) + '$' + id + '$' + this.$mainImage.value.substr(this.$mainImage.selectionEnd)
			const reader = new FileReader();
			reader.onloadend = () => {
				this.$previewImage.src = reader.result;
			};
			reader.readAsDataURL(data.image);
			return;
		}

		if(data.textHtml)
		{
			let id = this.mainImageHTMLToImage(data.textHtml);
			if(id)
			{
				this.$mainImage.value = this.$mainImage.value.substring(0, this.$mainImage.selectionStart) + '$' + id + '$' + this.$mainImage.value.substr(this.$mainImage.selectionEnd)

				this.newFiles[id].then(blob => {
					const reader = new FileReader();
					reader.onloadend = () => {
						this.$previewImage.src = reader.result;
					};
					reader.readAsDataURL(blob);
				});
				return;
			}
		}

		
		if(data.textPlain)
		{
			let id = parseUuidReplacement(data.textPlain);
			if(id)
			{
				this.newFiles[id].then(blob => {
					const reader = new FileReader();
					reader.onloadend = () => {
						this.$previewImage.src = reader.result;
					};
					reader.readAsDataURL(blob);
				});
			}
			else 
			{
				// todo validate its a url
				this.$previewImage.src = data.textPlain;
			}

			this.$mainImage.value = this.$mainImage.value.substring(0, this.$mainImage.selectionStart) + data.textPlain + this.$mainImage.value.substr(this.$mainImage.selectionEnd)
			
		}
	}

	async handleBodyPaste(data)
	{
		if(data.image)
		{
			let id = uuid();
			this.newFiles[id] = Promise.resolve(data.image);
			this.$markdown.value = this.$markdown.value.substring(0, this.$markdown.selectionStart) + '![]($' + id + '$)' + this.$markdown.value.substr(this.$markdown.selectionEnd)
			this.updateBodyPreview();
			return;
		}

		if(data.textHtml)
		{
			let markdown = this.bodyHtmlToMarkdown(data.textHtml);
			this.$markdown.value = this.$markdown.value.substring(0, this.$markdown.selectionStart) + markdown + this.$markdown.value.substr(this.$markdown.selectionEnd)
			this.updateBodyPreview();
			return;
		}

		if(data.textPlain)
		{
			this.$markdown.value = this.$markdown.value.substring(0, this.$markdown.selectionStart) + data.textPlain + this.$markdown.value.substr(this.$markdown.selectionEnd)
			this.updateBodyPreview();
		}
		
	}

	
	mainImageHTMLToImage(html)
	{
		var $div = document.createElement('div');
		$div.innerHTML = html;
		var $img = $div.querySelector('img');
		if(!$img)
			return null;

		var src = $img.getAttribute('src');

		if(!src)
			return null;

		let id = uuid();
		this.newFiles[id] = this.getFile(src);
		return id;
	}	

	getFile(src)
	{
		return fetch(getFileUrl() + '?url=' + encodeURIComponent(src)).then(r => r.blob())
	}

	downloadYoutube(id, youtubeId)
	{
		return fetch(downloadYoutubeUrl() + '?id=' + encodeURIComponent(id) + '&youtubeId=' + encodeURIComponent(youtubeId)).then(r => r.json())
	}

	bodyHtmlToMarkdown(html)
	{
		turndownRules.heading = {
			filter: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
		
			replacement: function (content, node, options) {
				var hLevel = Number(node.nodeName.charAt(1))
				return '\n\n' + repeat('#', hLevel) + ' ' + toTitleCase(node.textContent) + '\n\n'
			}
		}
		turndownRules.inlineLink = {
			filter: function (node, options) {
			  return (
				options.linkStyle === 'inlined' &&
				node.nodeName === 'A' &&
				node.getAttribute('href')
			  )
			},
		  
			replacement: (content, node) => {
				var href = node.getAttribute('href');
				if(href.endsWith('.pdf'))
				{
					let id = uuid();
					this.newFiles[id] = this.getFile(href);
					var title = cleanAttribute(node.getAttribute('title'))
					if (title)
						title = ' "' + title + '"'
					return '[' + content + ']($' + id + '$' + title + ')'
				}
				// var title = cleanAttribute(node.getAttribute('title'))
				// if (title) title = ' "' + title + '"'
				// return '[' + content + '](' + href + title + ')'
			  	return content
			}
		}
		turndownRules.image = {
			filter: 'img',
		  
			replacement: (content, node) => {
				var alt = cleanAttribute(node.getAttribute('alt'))
				var src = node.getAttribute('src') || ''
				let id = uuid();
				this.newFiles[id] = this.getFile(src);
				var title = cleanAttribute(node.getAttribute('title'))
				var titlePart = title ? ' "' + title + '"' : ''
				return src ? '![' + alt + ']' + '($' + id + '$' + titlePart + ')' : ''
			}
		};

		turndownRules.iframe = {
			filter: 'iframe',
			replacement: (content, node) => {
				let src = node.getAttribute('src');

				// handle youtube
				if(src.startsWith('https://www.youtube.com/embed/'))
				{
					let videoId = /https:\/\/www\.youtube\.com\/embed\/([^/&#]{11})/i.exec(src)[1]
					let id = uuid();
					this.newVideos[id] = this.downloadYoutube(id, videoId);
					return '$[$' + id + '$]($' + id + '$)';
				}
			}
		}

		var turndownService = new TurndownService({
			headingStyle: 'atx',
			rules: turndownRules
		})
		
		var markdown = turndownService.turndown(html)
		return markdown;
	}

	updateBodyPreview()
	{
		var html = marked.parse(this.$markdown.value);
		this.$previewBody.innerHTML = html;
		// update pasted images
		this.$previewBody.$('img').forEach($img => {
			let src = $img.getAttribute('src');
			if(!src.startsWith('$'))
				return;

			$img.dataset['imgId'] = src;
			let imgId = src.substr(1, src.length - 2);
			this.newFiles[imgId].then(blob =>{
				const reader = new FileReader();
				reader.onloadend = () => {
					$img.src = reader.result;
				};
				reader.readAsDataURL(blob);
			})
		})

		// update pasted links
		this.$previewBody.$('a').forEach($a => {
			let href = $a.getAttribute('href');
			if(!href.startsWith('$'))
				return;

			$a.dataset['file'] = href;
			let id = href.substr(1, href.length - 2);
			this.newFiles[id].then(blob =>{
				const reader = new FileReader();
				reader.onloadend = () => {
					$a.href = reader.result;
					$a.download = $a.textContent + '.pdf'
				};
				reader.readAsDataURL(blob);
			})
		})

		// update pasted vidoes
		this.$previewBody.$('video').forEach($video => {
			let poster = $video.getAttribute('poster');
			if(!poster.startsWith('$'))
				return;

			let mediaId = poster.substr(1, poster.length - 2);
			this.newVideos[mediaId].then(json =>{
				$video.poster = json.posterUrl;
				$video.src = json.videoUrl;
			})
		})
	}

	updateMetaPreview()
	{
		let difficulty = parseInt(this.$difficulty.value, 10);
		renderDifficulty(this.$previewDifficulty, difficulty);

		let danger = parseInt(this.$danger.value, 10);
		renderDanger(this.$previewDanger, danger);

		renderDuration(this.$previewDuration, this.$duration.value);
	}

	async boot(opts){
		// TODO: replace with listener
		if(opts.activityId != 0)
		{
			let unsub = await activityRepository.getActivity(opts.activityId, snapshot => {
				this.activity = snapshot.data();
			})
		}

		let tags = await activityRepository.listTags()
		this.tags = new TomSelect(this.$tags[0], {
			create: true,
			plugins: ['remove_button'],
			onItemAdd: function () {
				this.setTextboxValue('');
				this.refreshOptions();
			},
			options: tags.map(s => ({value: s, text: s }))
		});
	}

	populateForm()
	{
		this.$title.value = this.activity.title || '';
		this.$previewTitle.textContent = this.$title.value || 'Activity title';

		this.$difficulty.value = this.activity.difficulty || 1;
		this.$danger.value = this.activity.danger || 1;
		this.$duration.value = this.activity.duration || 20;

		this.$mainImage.value = this.activity.mainImage || '';
		this.$previewImage.src = this.$mainImage.value;

		this.$markdown.value = this.activity.body || '';

		this.$source.value = this.activity.source || '';

		this.tags.clear(true);
		this.activity.tags.forEach(tag => this.tags.addItem(tag, true));
		this.tags.blur();

		this.updateBodyPreview();
		this.updateMetaPreview();
	}

	show(opts) {
		if(opts.activityId == 0)
		{
			this.activity = {
				id: uuid(),
				tags: []
			};
		}

		this.populateForm();

		return super.show(opts);
	}

	hide(opts) {
		this.$form.reset();
		this.populateForm();

		return super.hide(opts);
	}
});
