import trullockForms from '@trullock/forms';
import {validation} from './language.js'
import {init as initFirebase} from './firebase.js'
import {init as initAuth, getCurrentUser} from './auth.js'
import * as pageManager from '@trullock/page-manager';
import { clearToast, showToast, updateFormGroupIds, showConfirm } from './utils.js'

import pageLoading from '../pages/loading.js';
import pageError from '../pages/error.js';
import pageError404 from '../pages/error-404.js';

import pageSignIn from '../pages/sign-in.js';
import pageSignOut from '../pages/sign-out.js';

import pageRoot  from '../pages/root.js';
import pageActivityEdit from '../pages/activity-edit.js';
import pageActivityView from '../pages/activity-view.js';
import pageTagView from '../pages/tag-view.js';
import pasteListener from './paste.js'

export default function() {
	var $navMenu;

	trullockForms(validation.messages);
	pasteListener();
	initFirebase();
	var authReady = initAuth();
	
	document.addEventListener('submitted', e => e.target.matches('form') && e.target.setSubmitting(true, e.detail))
	document.addEventListener('submission-complete', e => {
		if (!e.target.matches('form'))
			return;

		e.target.setSubmitting(false, e.detail.button);
	});


	document.addEventListener('click', e => {
		if(e.target.matches('a.disabled'))
		{
			e.preventDefault();
			e.stopImmediatePropagation();
			e.stopPropagation()
		}
	});

	window.addEventListener('DOMContentLoaded', function(){

		authReady.then(() => pageManager.init({
			fetchPath: route => `/pages/${route.routeName}.html`,
			prepareMarkup: $html => {
				$html.$('.form-group').forEach(updateFormGroupIds)
			},
			pageInterrupt: route => {
				if (route.pageClass.requireAuth && !getCurrentUser())
					return { url: pageManager.getPath('sign-in') };
				return null;
			},
			loadingPageName: 'loading',
			defaultPageName: 'root',
			error404PageName: 'error-404',
			beforeUnload: message => new Promise(resolve => {
				showConfirm({
					title: 'Confirm',
					message: message,
					positiveText: 'I\'m sure',
					negativeText: 'Cancel',
					positiveAction: () => { resolve(true); return Promise.resolve() },
					negativeAction: () => { resolve(false); return Promise.resolve() }
				});
			})
		}));
 
		$navMenu = document.querySelector('#navbarSupportedContent');
		document.querySelector('.navbar-toggler').addEventListener('click', e => {
			$navMenu.classList.toggle('show');
		});
		document.addEventListener('click', e => {
			$navMenu.classList.remove('show');
		}, true)

		// listen for navigations
		document.addEventListener('click', e => {
			if(e.ctrlKey || e.metaKey)
				return;

			var $a = e.target.matches('a') ? e.target : e.target.closest('a');
			if(!$a)
				return;

			if($a.target || $a.protocol == 'mailto:' || $a.protocol == 'data:')
				return;

			var href = $a.pathname + $a.search + $a.hash;
			if(href == '')
				return;

			if(href.startsWith('#'))
				return;
				
			pageManager.navigate(href, {}, true)

			e.preventDefault();
		}, false);

		// click blocker for accidental button bashing
		var clickBlocker = null;
		document.addEventListener('click', e => {
			var $target = e.target.matches('button, a') ? e.target : e.target.closest('button, a');
			if(!$target)
				return;
			
			if(clickBlocker)
			{
				e.stopPropagation();
				e.preventDefault();
				return;
			}

			clickBlocker = window.setTimeout(_ => clickBlocker = null, 250);
			
		}, true);

		// user state classes
		// bus.subscribe('user auth-state-changed', user => {
		// 	var $anons = document.$('.user-anon');
		// 	var $autheds = document.$('.user-authed');

		// 	if(user){
		// 		$anons.classList.add('d-none');
		// 		$autheds.classList.remove('d-none');
		// 	} else {
		// 		$anons.classList.remove('d-none');
		// 		$autheds.classList.add('d-none');
		// 	}
		// })

		// dropdown menus
		document.addEventListener('click', e => {
			var $button = e.target.matches('button[data-toggle=dropdown]') ? e.target : e.target.closest('button[data-toggle=dropdown]');
			
			var $menu = $button && $button.parentElement.$('.dropdown-menu')[0];

			document.$('.dropdown-menu').forEach($m => $m != $menu && $m.classList.remove('show'));
			
			if ($menu)
			{
				e.preventDefault();

				if($menu.className.indexOf('show') > -1)
				{
					$menu.classList.remove('show');
				} else {
					$menu.classList.add('show');

					var top = $button.offsetTop + $button.offsetHeight;
					$menu.style.top = (top + 5) + 'px';

					var bottom = $menu.offsetTop + $menu.offsetHeight;
					var $el = $menu;
					while ($el.offsetParent) {
						$el = $el.offsetParent;
						bottom += $el.offsetTop;
					}

					if(bottom > window.pageYOffset + window.innerHeight)
						window.scroll(0, bottom);
				}
			}

		}, false);

		document.addEventListener('page-shown', e=> {
			if (e.detail.opts.route.path != '/loading') {
				
				// // https://developers.google.com/gtagjs/reference/event#page_view
				// firebase.analytics().logEvent('page_view', {
				// 	page_location: window.location.protocol + '//' + window.location.host + e.detail.opts.route.path,
				// 	page_path: e.detail.opts.route.path,
				// 	page_title: e.detail.page.title
				// });
			}
		});

		document.addEventListener('page-hidden', e => {
			clearToast();
		});
	});
}
