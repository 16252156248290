
import { renderDanger, renderDifficulty, renderDuration } from './utils.js';
import { getPath, navigate } from '@trullock/page-manager';

export function renderCard($card, activity)
{
	$card.search = activity.title.toLowerCase();

	var $img = $card.$('.js-image');
	var $loader = $card.$('.spinner-4x3');
	$img.addEventListener('load', e=> {
		$loader.classList.add('d-none');
		$img.classList.remove('d-none')
	})
	$img.src = activity.mainImage;
	$img.alt = activity.title;

	$card.$('.js-edit').addClickListener(_ => {
		navigate(getPath('activity-edit', { activityId: activity.id }))
	});

	$card.$('.js-delete').addClickListener(_ => {
		showConfirm({
			title: 'Confirm',
			message: 'Are you sure you want to delete this activity?',
			positiveText: 'Delete',
			positiveStyle: 'danger',
			//positiveAction: () => projectRepository.deleteProject(proj.id)
		});
	});

	$card.$('.js-title').textContent = activity.title;

	$card.$('.js-view').forEach($a => {
		$a.href = getPath('activity-view', { activityId: activity.id });
	});

	$card.$('.js-title').textContent = activity.title;


	renderDifficulty($card.$('.js-difficulty'), activity.difficulty);
	renderDanger($card.$('.js-danger'), activity.danger);
	renderDuration($card.$('.js-duration'), activity.duration);

	let $tagTemplate = $card.$('.js-tag-template');
	let $tagWrapper = $tagTemplate.parentElement;
	$tagTemplate.remove();
	renderTags($tagTemplate, $tagWrapper, activity.tags)
	
}

export function renderTags($tagTemplate, $tagWrapper, tags)
{
	for(var tag of tags)
	{
		let $tag = $tagTemplate.cloneNode(true);
		$tag.textContent = tag;
		$tagWrapper.appendChild($tag);
		$tag.href = getPath('tag-view', { tag: tag});
	}
}