
import firebaseConfig from "../../firebase.config.js";
import { initializeApp } from "firebase/app";
import { } from 'firebase/auth';
import { } from 'firebase/functions';
import { } from 'firebase/firestore';
import { } from 'firebase/storage';
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

var _app;
export function init() {
	_app = initializeApp(firebaseConfig);

	// https://firebase.google.com/docs/emulator-suite

	if (location.hostname === 'localhost') {
		const storage = getStorage();
		connectStorageEmulator(storage, "localhost", 9199);

		const auth = getAuth();
		connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });

		const db = getFirestore();
		connectFirestoreEmulator(db, 'localhost', 8080);

		const functions = getFunctions(_app);
		connectFunctionsEmulator(functions, "localhost", 5001);
	}
}

export const app = _app;

export function getFileUrl() { return window.location.hostname == 'localhost' ? 'http://localhost:5001/steam-aca3b/europe-west2/getFile' : 'https://europe-west2-steam-aca3b.cloudfunctions.net/getFile'; }
export function downloadYoutubeUrl() { return window.location.hostname == 'localhost' ? 'http://localhost:5001/steam-aca3b/europe-west2/downloadYoutube' : 'https://europe-west2-steam-aca3b.cloudfunctions.net/downloadYoutube'; }