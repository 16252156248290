import { app } from './firebase.js'
import { getAuth, onAuthStateChanged, getRedirectResult } from 'firebase/auth';

var _currentUser;

export function init() {

	return new Promise(resolve => {
		let booted = false;
		const auth = getAuth(app);
		onAuthStateChanged(auth, user => {
			_currentUser = user;
			if(!booted){
				booted = true;
				resolve();
			}
		});
	});
}

export function getCurrentUser() { return _currentUser; }