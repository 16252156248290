function pasteListener(callback) {
	this.addEventListener('paste', async event => {
		event.preventDefault();

		var items = [...(event.clipboardData || event.originalEvent.clipboardData).items];
		
		let imageItem = items.find(i => i.type == "image/png" || i.type == "image/jpeg");
		let image = imageItem?.getAsFile();

		let textHtml = new Promise(resolve => {
			let item = items.find(i => i.type == "text/html");
			if(!item)
				resolve(null);
			else
				item.getAsString(resolve);
		});
		let textPlain = new Promise(resolve => {
			let item = items.find(i => i.type == "text/plain");
			if(!item)
				resolve(null);
			else
				item.getAsString(resolve);
		});
		// must not await until calling getAsString on all items
		textHtml = await textHtml;
		textPlain = await textPlain;
		
		// remove soft hyphens
		// textHtml = textHtml.replaceAll('&shy;', '');
		// textHtml = textHtml.replaceAll('&#xAD', '');
		// textHtml = textHtml.replaceAll('&#173', '');
		textHtml = textHtml && textHtml.replaceAll('\u00ad', '');
		textPlain = textPlain && textPlain.replaceAll('\u00ad', '');

		try
		{
			callback.call(this, {
				textHtml,
				textPlain,
				image
			});
		}
		catch(e)
		{
			console.error(e);
		}
	});
};


export default function paste()
{
	HTMLInputElement.prototype.addPasteListener = pasteListener;
	HTMLTextAreaElement.prototype.addPasteListener = pasteListener;
}