import "regenerator-runtime/runtime.js";

import css from "./scss/theme.scss";
import '@trullock/dollar'

import appicon from "./img/apple-touch-icon.png";
import fav32 from "./img/favicon-32x32.png";
import fav16 from "./img/favicon-16x16.png";
import favico from "./img/favicon.ico";

import sitewebmanifest from "./site.webmanifest";
import robotstxt from "./robots.txt"

// because webpack is broken/shit.
import cerebriSans from './fonts/cerebrisans-regular.woff';
window.cerebriSansPath = cerebriSans;

// import * as fb from "../js/firebase.js";
// fb.init(firebase);

import init from "./js/index.js";
init();