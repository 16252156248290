import Page from '../js/page.js';
import * as pageManager from '@trullock/page-manager';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

export default pageManager.registerPage('sign-in', '/sign-in', class extends Page {

	constructor($page) {
		super($page);
		
		this.$btnGoogle = this.$page.$('.js-google');
		this.$btnGoogle.addClickListener(_ => {
			var provider = new GoogleAuthProvider();
			const auth = getAuth();
			signInWithPopup(auth, provider).then(result => {
				pageManager.navigate('goal');
			}).catch(error => {
				console.error(error);
			});
		});
	}
})