import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, setDoc, getDoc, collection, getDocs, getFirestore, query, where, onSnapshot } from "firebase/firestore"; 

function wrapOnSnapshot(action, callback) {
	let firstRun = true;
	return new Promise(resolve => {
		var unsubscribe = onSnapshot(action(), snap => {
			var calledBack = Promise.resolve(callback(snap, !firstRun));
			if (firstRun)
				calledBack.then(() => resolve(unsubscribe));
			firstRun = false;
		}, e => {
			console.error(e);
			// TODO null vs []
			callback(null, false);
		});
	});
}

export async function uploadFile(name, blob)
{
	const storage = getStorage();
	const ref = storageRef(storage, 'files/activities/' + name);
	await uploadBytes(ref, blob);
	let url = await getDownloadURL(ref);
	return url;
}

export function saveActivity(activity)
{
	const database = getFirestore();
	let docRef = doc(database, 'activities', activity.id);
	return setDoc(docRef, activity);
}

export function getActivity(id, listenCallback)
{
	const database = getFirestore();
	return wrapOnSnapshot(() =>
		doc(database, "activities", id),
		listenCallback);
}

export async function listActivities(listenCallback)
{
	const database = getFirestore();
	return wrapOnSnapshot(() =>
		collection(database, "activities"),
		listenCallback);

}

export async function listActivitiesByTag(tag)
{
	const database = getFirestore();
	const activitiesRef = collection(database, "activities");
	const q = query(activitiesRef, where("tags", "array-contains", tag));
	const querySnapshot = await getDocs(q);
	return querySnapshot.docs.map(s => s.data());
}

export async function listTags()
{
	const database = getFirestore();
	const querySnapshot = await getDoc(doc(database, 'tags/all'));
	if(!querySnapshot.exists())
		return [];
	return querySnapshot.data().tags;
}
